<template>
  <div></div>
</template>

<script>
import Api from "@/assets/js/Api";
import Csrf from "@/assets/js/Csrf";
export default {
  name: "formfailed",
  mounted() {
    let app_url = "https://satec2022.klobbi.sg";

    let hash = this.$route.params.hash;
    let username = this.$route.params.username;
    setTimeout(() => {
      Csrf.getCookie().then((res) => {
        Api.post("/login", {
          hash: hash,
          username: username,
        }).then((res) => {
          console.log("%clogin.vue line:20 res", "color: #007acc;", res);

          // return;
          if (!res.data.error) {
            location.href = app_url + "/lobby";
            return;
          }
          location.href = app_url;
        });
      });
    }, 2000);
  },
};
</script>
